<template>
    <div class="articles">
        <div class="site-content animate">
            <main class="site-main">
                <article class="hentry">
                    <!-- 文章头部 -->
                    <header class="entry-header">
                        <!-- 标题输出 -->
                        <h1 class="entry-title">抱歉，没有此文章！！！请按文章标题进行搜索</h1>
                    </header>
                </article>
            </main>
        </div>
    </div>
</template>

<script>
    import './css/articles.less'
    export default {
        name: 'articles',
        data(){
          return{
             
          }
        },
    }
</script>
